import React, { useMemo, useState } from 'react';
import { FormControl, InputLabel } from '@mui/material';
import classNames from 'classnames';
import { Brick, BrickSetup } from 'components/bricks/types/brick.type';
import useComponentStore from 'components/data/ComponentStore/hooks/useComponentStore';
import MenuItem from 'components/ui-components-v2/MenuItem';
import Select from 'components/ui-components-v2/Select';
import ComponentStore from 'components/data/ComponentStore';
import BrickHelpers from 'components/bricks/helpers/brick.helpers';
import OutputDialogSocialPreview from './components/social-preview';
import OutputDialogCreativePreview from './components/creative-preview';
import OutputDialogSettingsPreview from './components/settings-preview';

import './styles/main.scss';

interface Props {
    bricks: Brick[];
}

interface ComponentStoreProps {
    previewItemId?: string;
}

const OutputDialogPreview: React.FC<Props> = ({ bricks }) => {
    const { previewItemId } = useComponentStore<ComponentStoreProps>('Bricks', {
        fields: { previewItemId: 'publishDialog.previewItemId' }
    });

    const currentBrick = useMemo(() => bricks.find((brick) => brick.id === previewItemId), [bricks, previewItemId]);

    const [formats, setFormats] = useState<string[]>([]);
    const [activeFormat, setActiveFormat] = useState<string>();

    const handleFormats = (formats: string[]) => {
        setFormats(formats);
        if (formats.length > 0) setActiveFormat(formats[0]); // Set the first format as active
    };

    const handleChangeItem = (e) => {
        ComponentStore.setModel('Bricks', 'publishDialog.previewItemId', e.target.value);
    };

    const handleActiveFormatChange = (e) => {
        setActiveFormat(e.target.value);
    };

    const getPreviewType = () => {
        if (!currentBrick) return;

        const setup: BrickSetup | undefined = BrickHelpers.getBrickData(currentBrick.subType, 'setup');
        return setup?.config?.general?.publish?.previewType;
    };

    const getFormats = () => {
        const previewType = getPreviewType();

        if (previewType === 'settings' || !previewType) return [];
        return formats;
    };

    const renderPreview = () => {
        if (!currentBrick) return;

        const previewType = getPreviewType();

        if (previewType === 'social') return <OutputDialogSocialPreview brick={currentBrick} handleFormats={handleFormats} activeFormat={activeFormat} />;
        if (previewType === 'creatives' && currentBrick?.data?.creatives) {
            return <OutputDialogCreativePreview brick={currentBrick} activeFormat={currentBrick.data.format} />;
        }
        return <OutputDialogSettingsPreview brick={currentBrick} />;
    };

    const showFormatsSelect = getFormats().length > 0;
    const showHeader = bricks.length > 1 || showFormatsSelect; // Show header only if there are multiple items or formats

    return (
        <div className="output-jobs-dialog-preview">
            {showHeader && (
                <div className="output-jobs-dialog-preview__header">
                    <FormControl variant="outlined">
                        <InputLabel id="select-item-label">Item</InputLabel>
                        <Select labelId="select-item-label" id="select-item" key={previewItemId} onChange={handleChangeItem} value={previewItemId} label="Item">
                            {bricks.map((item) => (
                                <MenuItem value={item.id} key={item.id}>
                                    <div className="output-jobs-dialog-preview__header__item">{item.title}</div>
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    {showFormatsSelect && (
                        <FormControl variant="outlined" style={{ marginLeft: 8 }}>
                            <InputLabel id="select-format-label">Formats</InputLabel>
                            <Select labelId="select-format-label" id="select-format" onChange={handleActiveFormatChange} value={activeFormat} label="Placement">
                                {getFormats().map((format) => (
                                    <MenuItem value={format} key={format}>
                                        <div className="output-jobs-dialog-preview__header__item">{format}</div>
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    )}
                </div>
            )}
            <div className={classNames('output-jobs-dialog-preview__content', { 'output-jobs-dialog-preview__content--full-height': !showHeader })}>
                {renderPreview()}
            </div>
        </div>
    );
};

export default OutputDialogPreview;
