import React, { useMemo } from 'react';
import { Row } from '@tanstack/react-table';
import TreeViewListItem from 'components/bricks/components/shared/components/tree-view-list/components/item';
import TypeIcon from 'components/bricks/components/shared/components/type-icon';
import useComponentStore from 'components/data/ComponentStore/hooks/useComponentStore';
import ComponentStore from 'components/data/ComponentStore';
import Icon from 'components/ui-components-v2/Icon';
import useValidations from 'components/bricks/hooks/useValidations';
import { useBricksPublish } from 'components/bricks/hooks/useBricksPublish';
import Tooltip from 'components/ui-components-v2/Tooltip';
import BrickSetupHelper from 'components/bricks/helpers/brick-setup-helper';
import BrickHelpers from 'components/bricks/helpers/brick.helpers';
import useBrick from 'components/bricks/hooks/useBrick';
import { BrickTree } from 'components/bricks/types/brick.type';
import OutputTreeViewItemHelper from '../helpers/output-tree-view-item.helper';

interface ComponentStoreProps {
    previewItemId?: string;
}

interface Props {
    item: Row<BrickTree>;
}

/**
 * This component is responsible for rendering the publish dialog tree view item.
 */
const OutputTreeViewItem: React.FC<Props> = ({ item }) => {
    const { previewItemId } = useComponentStore<ComponentStoreProps>('Bricks', { fields: { previewItemId: 'publishDialog.previewItemId' } });
    const { brick } = useBrick(item.id);
    const { errors, warnings: validationWarnings } = useValidations([item.id]);
    const { publishJobs } = useBricksPublish([item.id]);
    const recentPublishJob = publishJobs?.[0];
    const activePreviewItemId = item.id === previewItemId ? previewItemId : undefined; // Only pass the previewItemId if it matches the current item, this is to prevent unnecessary re-renders.

    if (!brick && !item.id.includes('-')) return null; // If the brick is not found and the item id does contain a dash then continue because it is a creative format.

    const warnings = useMemo(
        () => OutputTreeViewItemHelper.getValidationWarnings(recentPublishJob, validationWarnings, brick?.updatedAt),
        [validationWarnings, recentPublishJob, brick?.updatedAt]
    );
    const success = useMemo(() => OutputTreeViewItemHelper.getValidationSuccess(recentPublishJob), [recentPublishJob]);
    const severity = useMemo(() => OutputTreeViewItemHelper.getPublishSeverity(recentPublishJob, errors, warnings), [recentPublishJob, errors, warnings]);
    const isItemDisabled = severity === 'error';

    const handlePreviewChange = (event: React.MouseEvent, previewItemId: string) => {
        event.stopPropagation(); // Prevent other click events from being triggered
        ComponentStore.setModel('Bricks', 'publishDialog.previewItemId', previewItemId);
    };

    const handleOnItemClick = (event: React.MouseEvent, item: Row<BrickTree>) => {
        handlePreviewChange(event, item.id);

        if (!isItemDisabled) {
            item.toggleSelected();
        }
    };

    const renderIcon = () => {
        const iconSize = 24;

        if (!brick) return <Icon>photo_size_select_small</Icon>; // If the brick is not found then means it is a creative format.

        const subType: string | undefined = useMemo(() => BrickSetupHelper.getSetupBrickTitle(brick), [brick.id]);

        return (
            <Tooltip title={subType}>
                <div>
                    <TypeIcon width={iconSize} height={iconSize} subType={brick.subType} noBackground brickId={item.id} />
                </div>
            </Tooltip>
        );
    };

    return (
        <TreeViewListItem
            item={item}
            title={BrickHelpers.getBrickTitle(item.id, brick)}
            previewItemId={activePreviewItemId}
            onPreviewChange={handlePreviewChange}
            onItemClick={handleOnItemClick}
            icon={renderIcon()}
            severity={severity}
            isDisabled={isItemDisabled}
            tooltip={{ errors, warnings, success }}
        />
    );
};

export default OutputTreeViewItem;
